import React from "react"
import Layout from "../components/Layout"
import paperPlane from "../assets/images/paper-airplane.gif"
import SEO from "../components/SEO"

const Thanks = () => {
  return (
    <Layout>
      <SEO title="Thanks" />
      <main className="thank-you-page">
        <section>
            <div className="thanksImg">
            <img src={paperPlane} alt="Paper plane" />
            </div>
          <h3>Vaša poruka je uspješno poslana!</h3>
          <h4>Hvala na povjerenju! Odgovorit ćemo Vam uskoro.</h4>
        </section>
      </main>
    </Layout>
  )
}

export default Thanks
